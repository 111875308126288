export const monthList = [
  'component.date.jan',
  'component.date.fab',
  'component.date.mar',
  'component.date.apr',
  'component.date.may',
  'component.date.jun',
  'component.date.jul',
  'component.date.aug',
  'component.date.sep',
  'component.date.oct',
  'component.date.nov',
  'component.date.dec',
];
export const days = [
  'component.date.mon',
  'component.date.tue',
  'component.date.wed',
  'component.date.thu',
  'component.date.fri',
  'component.date.sut',
  'component.date.sun',
];
export function getYearList(): number[] {
  const list = [];
  let curYear = new Date().getFullYear();
  for (let i = 0; i < 3; i++) {
    list.push(curYear);
    curYear++;
  }
  return list;
}
